import React from "react";
import Logo from '../../assets/logos/ref-logo.svg'
import HeadingHor from '../../assets/ref-heading-hor.svg'
import HeadingVer from '../../assets/ref-heading-ver.svg'
import People from '../../assets/ref-people.svg'
import TgLogo from '../../assets/logos/ref-tg.svg';
import ViberLogo from '../../assets/logos/ref-viber-svg.svg';
import XCross from '../../assets/ref-icon-cross.svg'
import useWindowDimensions from "../../hooks/useWindowDimensions";

import LogoHP from '../../assets/logos/ref-hp-logo.svg';
import ST from '../../assets/logos/ref-ost.svg';
import Zubr from '../../assets/logos/zubr.svg';
import Golos from '../../assets/logos/ref-golos-logo.svg';
import Nau from '../../assets/logos/bottom/ref-nau.svg';
import KS from '../../assets/logos/ks.svg';

const Header = () =>  {

    const { width } = useWindowDimensions();

    const ICO_BASIC = width < 600 ? 30 : 30;
    const MENU_SIZE = width < 600 ? 15 : 20;
    const ICO_GAP = width < 600 ? 'mr-20 mb-20' : 'mr-30 mb-30';


    const logos_lines = [
        {logo: LogoHP, height: ICO_BASIC + 10},
        {logo: ST, height: ICO_BASIC + 20},
        {logo: Zubr, height: ICO_BASIC + 10},
        {logo: Golos, height: ICO_BASIC},
        {logo: Nau, height: ICO_BASIC + 10},
        {logo: KS, height: ICO_BASIC},
    ]

    const goto = (str) => {
        const el = document.getElementById(str);
        el.scrollIntoView({ behavior: 'smooth' });
    }

    return (
       <div className='app-centered-container' id='start'>
           <div className='app-section'>
               <div className='app-section-block'>
                    <img src={Logo} width={86} alt='logo' />
                </div>
               <div className={'app-section-block ' + (width < 600 ? 'd-flex mt-30' : 'd-flex-end')}>
                    <div className='d-flex-ac'>
                        <h2 className='mr-20 d-flex-ac enabledClick' onClick={() => goto('instructions')} style={{fontSize: MENU_SIZE}}>СТРАТЕГИЯ</h2>
                        <h2 className='mr-20 d-flex-ac enabledClick' onClick={() => goto('faq')} style={{fontSize: MENU_SIZE}}>ВОПРОСЫ</h2>
                        <div className='d-flex-ai-start'>
                            <a className='td-none fcblack' href='https://bit.ly/ref-golos-tg' target='_blank' rel='noopener noreferrer'>
                                <h2 style={{fontSize: MENU_SIZE}}>ПЛАТФОРМА ГОЛОС</h2>
                            </a>
                            <div className='ml-10 d-flex-ai-start'><img src={Golos} width={width < 600 ? 30 : 50} alt='golos' /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-50'>
                <div className='app-section-block-left' style={{left: -150}}>
                    <img src={XCross} width='100%' alt='logo' />
                </div>
                <div className='app-section-block-right' style={{right: -150}}>
                    <img src={XCross} width='100%' alt='logo' />
                </div>
                <div className='d-web'>
                    <img src={HeadingHor} width='100%' alt='logo' />
                </div>
                <div className='d-mobile'>
                    <img src={HeadingVer} width='100%' alt='logo' />
                </div>
            </div>

           <div className='app-section mt-50'>
               <div className='app-section-block'>
                   <div className={width > 600 ? 'wp-70' : 'wp-100'}>
                       <p><strong>27 февраля 2022 года в Беларуси запланирован «референдум» о принятии новой конституции.</strong></p>
                       <p className='mbweb-20 mt-10'>Этот «референдум» – преступление. На нем Беларусов попытаются обмануть и вместо голосования за реальные перемены сфальсифицировать народную поддержку режима. </p>
                   </div>
               </div>
               <div className='app-section-block'>
                   <img src={People} width='100%' alt='people' />
               </div>
            </div>

            <div className='app-section mt-50'>

                <div className='app-section-block'>
                    <div className={width > 800 ? 'wp-70' : 'wp-100'}>
                        <h1>ДЕЙСТВОВАТЬ ВМЕСТЕ – ЗНАЧИТ ПОБЕДИТЬ</h1>
                        <p className='mbweb-20'>Выберите удобный  мессенджер, чтобы объединиться на платформе “Голос” </p>
                        <div className='mt-20 d-flex-ac'>
                            <a className='td-none' href='https://bit.ly/ref-golos-viber' target='_blank' rel='noopener noreferrer'>
                                <button className='button-main viber d-flex-ac'>
                                    <img src={ViberLogo} width={24} alt='viber' />
                                    <div className='ml-5'>Viber</div>
                                </button>
                            </a>
                            <a className='td-none' href='https://bit.ly/ref-golos-tg' target='_blank' rel='noopener noreferrer'>
                                <button className='ml-10 button-main telegram d-flex-ac'>
                                    <img src={TgLogo} width={24} alt='telegram' />
                                    <div className='ml-5'>Telegram</div>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='app-section-block'>
                    <div className='d-flex-wrap mt-50'>
                        {logos_lines.map((item, index) => <div key={index} className={ICO_GAP}>{<img height={item.height} src={item.logo} alt='logo' />}</div>)}
                    </div>
                    <div className='mt-15 link'>
                        <strong>Единая стратегия</strong> демократических сил Беларуси.<br />
                        <p className='enabledClick url' onClick={() => goto('strategy')}>Подробнее об участниках</p>
                    </div>
                </div>

            </div>

       </div>


    );
}

export default Header;
