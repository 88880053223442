import React, {useState} from "react";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";

const FAQ = [
    {
        question: 'Можно ли участвовать в «Референдуме»?',
        answer: <p>Участвовать в «Референдуме» — значит выбирать один из предложенных вариантов. Не важно, проголосуете вы «за» или «против», Лукашенко устроит любой результат, ведь признать «Референдум» — это согласиться с правом режима решать будущее страны. <br /><br />Единая стратегия демократических сил – это выбор не быть соучастником преступления и донести свою позицию. Для этого нужно прийти на участок и сделать бюллетень недействительным. Так мы сможем помешать планам режима и покажем, как нас много.
        </p>
    },
    {
        question: 'Почему просто не бойкотировать «Референдум»? ',
        answer: <p>Пустые участки и апатия беларусов — настоящий подарок нелегитимному президенту. Такую картинку можно не только показать по БТ, но и презентовать зарубежным партнерам.
            <br /><br />
            Больше всего режим боится протестной явки. Бойкот может показаться самым простым вариантом, но именно так мы расписываемся в собственной беспомощности. Любая электоральная кампания — это зона турбулентности режима, которой нужно воспользоваться.
            <br /><br />
            Максимально безопасный способ выражения несогласия – прийти на участок, сделать бюллетень недействительным и подтвердить действие онлайн.
            <br /><br />
            Разбираемся, что не так с главными аргументами сторонников пассивного бойкота.
            <br /><br />
            <strong>«Режиму нужна явка».</strong> Режиму нужна явка только своих сторонников, а также безразличных бюджетников и сотрудников госаппарата, которых сгоняют на участки. В реальности больше всего нелегитимная власть боится протестной явки. Увидеть на избирательных участках сотни людей, которые выступают против Лукашенко,  — страшный сон главы ЦИК. Собравшись вместе в день голосования, мы вновь ощутим себя огромной силой.
            <br /><br />
            Почему еще важно не оставаться в стороне?
            <br /><br />
            <strong>Возможность проверить, что твой голос не украден.</strong> Даже если вы не придете на участок, вашего отсутствия, к сожалению, никто не заметит — режим компенсирует его фальсификацией явки. Так было на протяжении всех 27 лет. Пассивный бойкот позволяет нелегитимным властям провести «Референдум» по их сценарию: все тихо, спокойно, а несогласных вроде как  не существует. Пассивный бойкот не может показать, что большинство беларусов против Лукашенко.
            <br /><br />
            Бюджетники, студенты, сотрудники госпредприятий — этих людей сгоняют на участки, порой под угрозой увольнений и отчислений. Нет сомнения, что так будет происходить и в этот раз.  Для тех, кто не может отказаться от участия в «Референдуме», сделать бюллетень недействительным и перечеркнуть беззаконие — это <strong>возможность  действовать по сценарию демократических сил</strong>, а не так, как рассчитывают чиновники из ЦИК.
            <br /><br />
            <strong>Это возможность продемонстрировать свою гражданскую позицию.</strong> Если оставаться дома, никто не узнает, поддерживаете ли вы политику Лукашенко или не согласны с ней. В то же время протестная явка создает сильнейший стресс для режима и способна вывести систему из равновесия. </p>
    },
    {
        question: 'Как сделать бюллетень недействительным?',
        answer: <p>Поставить крестики напротив всех предложенных вариантов.
            <br /><br />
            Избирательный кодекс говорит, что недействительными признаются бюллетени неустановленного образца, а также бюллетени, в которых поставлен знак в двух квадратах или не поставлен ни в одном из них.
            <br /><br />
            Мы предлагаем ставить крестики за все варианты, так как если оставить ячейки пустыми, есть вероятность, что члены комиссии помогут знакам появиться там, где нужно.
            <br /><br />
            Главное, не забывайте, что “референдум” – незаконный, поэтому не важно, какие и как будут сформулированы вопросы, мы должны перечеркнуть планы режима.
        </p>
    },
    {
        question: 'Чем отличается испорченный бюллетень от недействительного?',
        answer: <p>Испорченный бюллетень — это тот, что избиратель испортил по невнимательности и обменял у комиссии на новый. Такой бюллетень не учитывается при подсчете голосов.
            <br /><br />
            Недействительный бюллетень — по сути, тот же испорченный, но опущенный в урну. Он учитывается в итоговом протоколе.
            <br /><br />
            В законодательстве есть лазейка: недействительным признается тот бюллетень, в отношении которого комиссия не смогла установить, какой выбор сделал избиратель. Если, к примеру, разрисовать бюллетень, но оставить ячейки пустыми, члены комиссии могут «проголосовать» за вас. Именно поэтому мы призываем ставить крестики напротив всех предложенных вариантов.
        </p>
    },
    {
        question: 'Безопасно ли делать бюллетень недействительным?',
        answer: <p>Учитывая атмосферу репрессий и запугивания, в которой сейчас находится Беларусь, это наиболее безопасный способ активно выразить свою позицию.
            <br /><br />
            Избирательный кодекс и другие законодательные акты не запрещают делать бюллетень недействительным. Также никто не вправе контролировать, какой выбор сделал человек.
        </p>
    },
    {
        question: 'Зачем считать недействительные бюллетени?',
        answer: <p>Мы не питаем иллюзий, что честные результаты будут официально опубликованы. Но как на выборах 2020 были честные участки, так и на “референдуме” будут люди, которые не смогут врать народу. Количество недействительных бюллетеней должно быть указано в итоговом протоколе на каждом участке. Вместе с альтернативным подсчетом это поможет выявить еще больше участков, которые опубликуют фальсифицированные результаты, чтобы в будущем привлечь к ответственности виновных.
            <br /><br />
            Когда члены комиссий знают, что за ними следят, – им сложнее обманывать и совершать преступления. Так мы добьемся того, чтобы вся система почувствовала, что большинство беларусов не приемлет беззаконие. Придя на участки в день голосования, мы снова ощутим себя огромной силой.
        </p>
    },
    {
        question: 'Как мы будем противодействовать фальсификации?',
        answer: <p>Искать честных членов избирательных комиссий. Во время выборов 2020 года были участки, на которых честно считали голоса. В этом году их будет больше. Проект “Перечеркни фальсификации” помогает присоединиться к другим честным членам избирательных комиссий, чтобы собирать свидетельства преступлений фальсификаторов и противодействовать им.
            <br /><br />
            Вы можете помочь противодействию простым участием: чем больше людей придет и выскажет свою позицию, тем сложнее будет подделать итоговый результат.
            <br /><br />
            Все недействительные бюллетени должны учитываться в итоговых протоколах. Количество избирателей, которые решат перечеркнуть беззаконие, мы пересчитаем на платформе «Голос». Это эффективный способ понять масштабы фальсификаций.
            <br /><br />
            Поэтому чем больше людей придет и выскажет свою позицию, тем сложнее будет чиновникам ЦИК убедить в итогах голосования как самих беларусов,  так и мировое сообщество.
            <br /><br />
            Вдохновите считать честно. Если ваши знакомые участвуют в избирательных комиссиях, объясните им, что их роль значима и от них зависит многое. Если вы знаете тех, кому поручено давить на членов комиссии, постарайтесь убедить их не делать этого, так как преступление такого масштаба не останется безнаказанным.</p>
    },
    {
        question: 'А что, если «Референдум» отменят?',
        answer: <p>По предварительным данным, “референдум” должен забетонировать власть режима и полностью отсечь от управления народ. Поэтому, если режим испугается того, что его планы будут перечеркнуты, – это будет важной победой воли народа.
            <br /><br />
            Это значит, что Лукашенко лишится возможности показать мировому сообществу или тем, кто еще верит пропаганде, что у него есть сторонники и что беларусы согласны перевернуть страницу.
            А мы будем готовиться для следующей возможности.</p>
    },
    {
        question: 'Что нужно делать до «Референдума»?',
        answer: <p>Объединяйтесь на платформе “Голос” и следите за новостями. Расскажите друзьям и знакомым о «Референдуме». Объясните, в чем заключается единая стратегия демократических сил и почему участие лучше бойкота.
            <br /><br />
            Общайтесь с теми, кто может быть связан с избирательными комиссиями. Если вам предложили стать членом ИК — напишите в телеграм-аккаунт t.me/contact2022
            <br /><br />
            Если у вас есть родственники, которые работают в госучреждениях, расскажите им, как собирать свидетельства фальсификаций.
            <br /><br />
            Распространите <a href='https://drive.google.com/file/d/1wFtv6iv73_NnEhCIZ-9Jeuf2qPLYGMZo/view' target='_blank' rel='noopener noreferrer'>листовку</a>, которая поможет искать честных членов ИК.</p>
    },
    {
        question: 'Какими статьями будут угрожать участникам?',
        answer: <p>Выполнение всех действий Единой стратегии – полностью легально. Делать бюллетень недействительным Избирательный кодекс и другие законодательные акты не запрещают.
            <br /><br />
            В 2020 году даже провластный ЦИК признавал, что в законах нет конкретных правил, как обращаться с бюллетенем. Звучали заявления, что, если бюллетень испорчен демонстративно, может быть составлен протокол за нарушение законодательства о выборах. Но демонстративно — значит на глазах избирательной комиссии или журналистов. Тайное голосование никто не вправе контролировать.
            <br /><br />
            Силовики угрожают, что могут вычислить всех интернет-пользователей. Это выглядит как попытка посеять панику и заставить беларусов бездействовать. Платформа «Голос» не хранит никаких личных данных. К разработчикам попадает только фотография бюллетеня, которой автоматически присваивается случайный номер, чтобы исключить повторы и правильно подсчитать голоса. Все сервера находятся за границей. Получить к ним доступ невозможно: даже если платформа будет взломана, никто и никогда не узнает, кто отправлял ей свои голоса.
            <br /><br />
            В Избирательном кодексе сказано, что запрещены призывы, «побуждающие или имеющие своей целью побуждение к срыву, или отмене, или переносу срока выборов, референдума, назначенных в соответствии с законодательными актами Республики Беларусь». Поэтому, если вы распространяете информацию с призывами перечеркнуть референдум – это может быть расценено как призыв к срыву. В то же время, распространять информацию о поиске честных членов избирательных комиссий, а также следовать единой стратегии самому — не является нарушением закона.</p>
    },
    {
        question: 'Мы придем на участки и пересчитаем наши голоса в онлайне, но что дальше?',
        answer: <p>Нам часто пишут, что без дальнейшего плана действий единая стратегия на «Референдуме» не приведет к глобальным переменам. Что это помешает Лукашенко получить легитимность на международной арене, но внутри страны ничего не изменится.
            <br /><br />
            Сейчас беларусам важно увидеть, как нас много, чтобы вернуть уверенность в своих силах. Поэтому единая базовая стратегия «Перечеркни беззаконие» останется прежней.
            <br /><br />
            План действий на «Референдуме» будет дополняться. Мы не озвучиваем детали плана сейчас,  потому что считаем, что так безопаснее.
        </p>
    }
]

const Faq = () =>  {

    const [selected, setSelected] = useState(null);

    return (
       <div className='app-centered-container mt-100' id='faq'>
           <div>
               <div className='ta-center'>
                   <h1>Популярные вопросы</h1>
               </div>
               <div className='wp-100 mt-50'>
               {
                   FAQ.map((item, index) => {
                       const headingStyle = selected === index ? 'faq-container-selected' : 'faq-container-red'
                       const arrow = selected === index ? <FiChevronDown size={24} /> : <FiChevronRight size={24} />
                       return <div>
                           <div key={index} className={'enabledClick mt-30 ' + headingStyle} onClick={() => setSelected(selected === index ? null : index)}>
                               <div className='d-flex-ac'>
                                   {arrow}
                                   <div className={'ml-10 '}>{item.question}</div>
                               </div>
                           </div>
                           {selected === index &&
                               <div className='mt-20 pl-50'>
                                   {item.answer}
                               </div>
                           }
                       </div>
                   })
               }
               </div>
            </div>


       </div>


    );
}

export default Faq;
