import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import Picture1 from '../../assets/pictures/tablet_pic1.png'
import Picture2 from '../../assets/pictures/tablet_pic2.png'
import Picture3 from '../../assets/pictures/tablet_pic3.png'
import Picture4 from '../../assets/pictures/tablet_pic4.png'
import Picture5 from '../../assets/pictures/tablet_pic5.png'
import Picture6 from '../../assets/pictures/tablet_pic6.png'

import downloadIco from '../../assets/icons/save_alt.svg'

const Points = () =>  {

    const { width } = useWindowDimensions();

    return (
       <div className='app-centered-container mt-200'>
           <div className='app-section mt-100'>
               <div className='app-section-block mb-20'>
                    <img src={Picture1} width='100%' alt='point' />
                </div>
               <div className='app-section-block'>
                    <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
                        <h2 className='tt-up'>Борьба с преступлением государства – задача граждан </h2>
                        <p className='mt-20'>Действующая Конституция была многократно нарушена – новый текст может только закрепить эти нарушения.<br /><br />Цель голосования между “Конституцией Лукашенко” и “Новой Конституцией Лукашенко”– сфальсифицировать поддержку режима после проигранных выборов.<br /><br />
                            Это событие затронет всех беларусов, но каждый решает сам – молчаливо участвовать в нем или сделать возможное для противодействия.</p>
                    </div>
                </div>
            </div>
           <div className='app-section mt-100 d-flex-mobile-row-reverse' >
               <div className='app-section-block'>
                   <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
                       <h2 className='tt-up'>Каждый из нас формирует образ Беларуси будущего</h2>
                       <p className='mt-20'>Люди – самое ценное, что может быть у страны. <br /><br />Это мы построили и создали всё, что сегодня есть в Беларуси, это наши предки умирали ради нашей свободы.<br /><br /> Народ Беларуси достоин того, чтобы базовые права человека были нерушимы, а независимость страны не была предметом торга. <br /><br />Вместе мы сможем защитить наши ценности и перечеркнуть насилие, ложь, бедность и страх.</p>
                   </div>
               </div>
               <div className='app-section-block'>
                   <img src={Picture2} width='100%' alt='point' />
               </div>
           </div>
           <div className='app-section mt-100'>
               <div className='app-section-block'>
                   <img src={Picture3} width='100%' alt='point' />
               </div>
               <div className='app-section-block'>
                   <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
                       <h2 className='tt-up'>Перечеркни насилие</h2>
                       <p className='mt-20'>В 2020 году мы проголосовали за перемены. Но бывший президент отказался покидать свой пост. <br /><br />Потеряв поддержку людей, он использовал единственный аргумент - насилие. В его арсенале нет ничего, на чем держалась бы власть, кроме дубинок, обысков и арестов. Десятки тысяч беларусов прошли через аресты, тысячи до сих пор в заключении. <br /><br />Но насилие можно прекратить, взяв судьбу в свои руки. Перечеркивая предложения режима, мы говорим: “Нет!” – и делаем первый шаг, чтобы остановить насилие.</p>
                   </div>
               </div>
           </div>
           <div className='app-section mt-100 d-flex-mobile-row-reverse'>
               <div className='app-section-block'>
                   <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
                       <h2 className='tt-up'>Перечеркни обман</h2>
                       <p className='mt-20'>“Референдум” – это обман и преступление, в котором нас убеждают принять участие. <br /><br />Нам предлагают ложный выбор между новой и старой Конституцией. На самом деле это выбор между Лукашенко и Лукашенко. Обман лежит в основе режима: нам врут про зарплаты, нам врут на выборах, нам врут про коронавирус. <br /><br />Но правда на нашей стороне. Мы придем на референдум и перечеркнем оба лживых предложения. Это первый шаг к правде.<br/><br/>Распространяйте правду – скачайте макеты листовок и стикеров<br/><br/><a href='https://bitly.com/stickers_ref' target='_blank' rel='noopener noreferrer'><button className='button-black'><div className='d-flex-ac'>Скачать <img className='ml-5' src={downloadIco} alt='download'/></div></button></a></p>
                   </div>
               </div>
               <div className='app-section-block'>
                   <img src={Picture4} width='100%' alt='point' />
               </div>
           </div>
           <div className='app-section mt-100'>
               <div className='app-section-block'>
                   <img src={Picture5} width='100%' alt='point' />
               </div>
               <div className='app-section-block'>
                   <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
                       <h2 className='tt-up'>Перечеркни СТРАХ</h2>
                       <p className='mt-20'>Режим использует наш страх, лишив нас безопасности. Это главный инструмент, который у него остался. <br /><br />Но мы знаем, что больше всех в стране боится один человек - тот, кому люди сказали: “Уходи!”. Он боится ответственности, боится народа, боится будущего. Его время прошло.<br /><br />Вернуть себе безопасность можем только мы сами. <br /><br />Прийти на референдум и сделать бюллетень недействительным - это первый шаг в преодолении страха.</p>
                   </div>
               </div>
           </div>
           <div className='app-section mt-100 d-flex-mobile-row-reverse'>
               <div className='app-section-block'>
                   <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
                       <h2 className='tt-up'>Перечеркни бедность</h2>
                       <p className='mt-20'>Четверть века страна топчется на месте. Четверть века отложенных реформ и упущенных возможностей. В результате - серость и бедность. “Всем по 500$” как заветная цель, к которой идем и не можем дойти уже 10 лет.
                           <br /><br />
                           Мы достойный, инициативный и трудолюбивый народ. И мы можем жить так, как того желаем. Прийти на референдум и сделать бюллетень недействительным - это первый шаг к отказу от бедности.</p>
                   </div>
               </div>
               <div className='app-section-block'>
                   <img src={Picture6} width='100%' alt='point' />
               </div>
           </div>

       </div>


    );
}

export default Points;
