import React from "react";

import "./main.css";
import "./controls.css";
import "./shortcuts.css";
import "./sliding-pane.css"
import Header from "../header/header";
import Instructions from "../instructions/instructions";
import Points from "../points/points";
import Faq from "../faq/faq";
import Strategy from "../strategy/strategy";
import Footer from "../footer/footer";
import Uiks from "../uiks/uiks";

const App = () =>  {

    return (
        <div className="app-wrapper">
            <Header />
            <Instructions />
            <Points />
            <Faq />
            <Strategy />
            <Uiks />
            <Footer />
        </div>
    );
}

export default App;
