import React from "react";

const Footer = () =>  {

    return (
       <div className='wp-100 h-100 bg-black'>
           <br />
           <br />
       </div>
    );
}

export default Footer;
