import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Uiks = () =>  {

    const { width } = useWindowDimensions();

    return (
       <div className='app-centered-container mt-50 mb-50'>
           <div className={(width > 800 ? 'pr-50 pl-50 wp-80' : 'mt-30 wp-100')}>
               <h2 className='tt-up'>Противодействие фальсификации</h2>
               <p className='mt-20'>Август 2020 изменил не только нас, но и всех, кто участвовал в фальсификациях, которые спровоцировали массовые протесты.
                   <br /><br />
                   Поэтому, мы уверены, что в этот раз еще больше членов избирательных комиссий не подчинятся приказам искажать результаты. Чтобы каждый мог вдохновить своего знакомого, соседа или коллегу считать честно и фиксировать все нарушения на которые его толкают, мы запустили проект “Перечеркни фальсификации”
                   <br /><br />
                   Переходите по ссылке, чтобы найти свой участок и узнать, кто будет считать ваш голос в этот раз<br/><br/><a href='https://bit.ly/izbirkom2022' target='_blank' rel='noopener noreferrer'><button className='button-black'><div className='d-flex-ac'>Перейти на сайт проекта </div></button></a></p>
           </div>

       </div>


    );
}

export default Uiks;
