import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import Logo1 from '../../assets/logos/bottom/ref-hp.svg';
import Logo2 from '../../assets/logos/bottom/ref-ost.svg';
import Logo3 from '../../assets/logos/zubr.svg';
import Logo4 from '../../assets/logos/bottom/ref-golos.svg';
import Logo5 from '../../assets/logos/bottom/ref-nau.svg';
import Logo6 from '../../assets/logos/ks.svg';
import Logo7 from '../../assets/logos/bottom/ref-bmsf.svg';
import Logo8 from '../../assets/logos/bottom/ref-shod.svg';
import Logo9 from '../../assets/logos/bottom/ref-roze.svg';
import Logo10 from '../../assets/logos/bottom/ref-byprosvet.svg';
import Logo11 from '../../assets/logos/bottom/ref-embassies.svg';
import Logo12 from '../../assets/logos/bottom/ref-roze2.svg';
import Logo13 from '../../assets/logos/bottom/ref-kpd.svg';
import Logo14 from '../../assets/logos/bottom/ref-7chizh.svg';
import Logo15 from '../../assets/logos/bottom/ref-bz.svg';
import Logo16 from '../../assets/logos/bottom/ref-drivers.svg';
import Logo17 from '../../assets/logos/bottom/ref-vitebsknews.svg';
import Logo18 from '../../assets/logos/bottom/ref-grodnonews.svg';
import Logo19 from '../../assets/logos/bottom/ref-mogilevnews.svg';
import Logo20 from '../../assets/logos/bottom/ref-gomelnews.svg';
import Logo21 from '../../assets/logos/bottom/ref-bv.svg';
import Logo22 from '../../assets/logos/bottom/ref-stoppropaganda.svg';
import Logo23 from '../../assets/logos/bottom/ref-masyukovschina.svg';
import Logo24 from '../../assets/logos/bottom/ref-gedemin.svg';
import Logo25 from '../../assets/logos/bottom/ref-honestmagazine.svg';
import Logo26 from '../../assets/logos/bottom/ref-mkb.svg';
import Logo27 from '../../assets/logos/bottom/ref-belamova.svg';
import Logo28 from '../../assets/logos/bottom/ref-sdz.svg';
import Logo29 from '../../assets/logos/bottom/ref-ruh-fr.png';


const Strategy = () =>  {

    const { width } = useWindowDimensions();

    const ICO_BASIC = width < 600 ? 15 : 30;
    const ICO_GAP = width < 600 ? 'd-flex-center w-100 h-100 mr-20 mb-20' : 'd-flex-center w-150 h-100 mr-30 mb-30';


    const logos_lines = [
        {logo: Logo1, height: ICO_BASIC},
        {logo: Logo2, height: ICO_BASIC},
        {logo: Logo3, height: ICO_BASIC},
        {logo: Logo4, height: ICO_BASIC},
        {logo: Logo5, height: ICO_BASIC},
        {logo: Logo6, height: ICO_BASIC},
        {logo: Logo7, height: ICO_BASIC},
        {logo: Logo8, height: ICO_BASIC},
        {logo: Logo9, height: ICO_BASIC},
        {logo: Logo10, height: ICO_BASIC},
        {logo: Logo11, height: ICO_BASIC},
        {logo: Logo12, height: ICO_BASIC},
        {logo: Logo13, height: ICO_BASIC},
        {logo: Logo14, height: ICO_BASIC},
        {logo: Logo15, height: ICO_BASIC},
        {logo: Logo16, height: ICO_BASIC},
        {logo: Logo17, height: ICO_BASIC},
        {logo: Logo18, height: ICO_BASIC},
        {logo: Logo19, height: ICO_BASIC},
        {logo: Logo20, height: ICO_BASIC},
        {logo: Logo21, height: ICO_BASIC},
        {logo: Logo22, height: ICO_BASIC},
        {logo: Logo23, height: ICO_BASIC},
        {logo: Logo24, height: ICO_BASIC},
        {logo: Logo25, height: ICO_BASIC},
        {logo: Logo26, height: ICO_BASIC},
        {logo: Logo27, height: ICO_BASIC},
        {logo: Logo28, height: ICO_BASIC},
        {logo: Logo29, height: ICO_BASIC},
    ]

    return (
       <div className='app-centered-container mt-50' id='strategy'>

           <h1>Единая стратегия</h1>
           <p className='mt-20'>С первого дня упоминания о “референдуме” демократические силы разрабатывали наиболее эффективный план возможных совместных действий.
               <br /><br />
               Этот проект – результат совместной работы гражданских инициатив *Честные люди, ZUBR, Платформы «Голос» при участии Офиса Светланы Тихановской, Народного антикризисного управления и Координационного совета. </p>
            <div className='app-section mt-50'>

                <div className='d-flex-wrap-ac mt-50'>
                    {logos_lines.map((item, index) => <div key={index} className={ICO_GAP}>{<img style={{maxWidth: '90%', maxHeight: '70%'}} src={item.logo} alt='logo' />}</div>)}
                </div>


            </div>

       </div>


    );
}

export default Strategy;
