import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ReactPlayer from "react-player";

import Done1 from '../../assets/icons/done_24px.svg'
import Paper from '../../assets/ref-paper.png'
import XCrossWhite from "../../assets/ref-icon-whitecross.svg";
import ViberLogo from "../../assets/logos/ref-viber-svg.svg";
import TgLogo from "../../assets/logos/ref-tg.svg";

const Instructions = () =>  {

    const { width } = useWindowDimensions();

    const videoWidth = Math.min(1152,width) - 40;
    const videoHeight = (Math.min(1152,width) - 40) * 9 / 16;


    return (
       <div className='app-full-container p-relative' id='instructions'>
           <div className='app-centered-container p-absolute'>
               <ReactPlayer width={videoWidth}
                            height={videoHeight}
                            playing={false}
                            url={'https://youtu.be/QOaF-ALBXZY'} />
           </div>

           <div className='app-red-block' style={{marginTop: videoHeight / 2 + 20, paddingTop: videoHeight/2 + 100}}>
               <div className='app-centered-container ta-center'>
                   <div className='app-section-block-left d-web' style={{left: -50}}>
                       <img src={XCrossWhite} width='100%' alt='logo' />
                   </div>
                   <div className='app-section-block-right d-web' style={{right: -50}}>
                       <img src={XCrossWhite} width='100%' alt='logo' />
                   </div>
                   <h1>ЧТО ДЕЛАТЬ<br/>В ДЕНЬ ГОЛОСОВАНИЯ</h1>
                   <div className='mt-30 app-section'>
                       <div className='app-section-block ta-left'>
                           <div className='d-flex-ac-sb mt-50'>
                               <div className='d-flex-ac'>
                                   <h1>1</h1>
                                   <p className='ml-30'>27 февраля – наш день, чтобы законно выразить несогласие и поставить два креста <span style={{fontSize: 20, fontWeight: 700}}>XX</span> на планах режима.
                                   </p>
                               </div>
                               <div className='w-30'><img src={Done1} alt='done' /></div>
                           </div>
                           <div className='d-flex-ac-sb mt-50'>
                               <div className='d-flex-ac'>
                                   <h1>2</h1>
                                   <p className='ml-30 pr-30'>В <span style={{fontSize: 20, fontWeight: 700}}>14:00</span> встречаемся на участках в масках и говорим друг другу “Прывітанне!”.</p>
                               </div>
                               <div className='w-30'><img src={Done1} alt='done' /></div>
                           </div>
                           <div className='d-flex-ac-sb mt-50'>
                               <div className='d-flex-ac'>
                                   <h1>3</h1>
                                   <p className='ml-30 pr-30'>Выразим несогласие, не нарушая закон, – поставим крестики напротив обоих вариантов и опустим в урну недействительные бюллетени.
                                   </p>
                               </div>
                               <div className='w-30'><img src={Done1} alt='done' /></div>
                           </div>
                           <div className='d-flex-ac-sb mt-50'>
                               <div className='d-flex'>
                                   <h1>4</h1>
                                   <div className='ml-30 pr-30'>
                                       Подтвердим наш выбор в “Голосе”, если будет возможность – отправим фото бюллетеня с 2х сторон.
                                   </div>
                               </div>
                               <div className='w-30'><img src={Done1} alt='done' /></div>
                           </div>
                           <div className='d-flex-ac-sb mt-50'>
                               <div className='d-flex'>
                                   <h1>5</h1>
                                   <div className='ml-30 pr-30'>
                                       В <span style={{fontSize: 20, fontWeight: 700}}>20:00</span>, если это безопасно, сфотографируем протокол на участке и отправим в его «Голос».
                                       <p className='mt-100'>
                                           Покажем несогласие всей страны с беззаконием, которое уже коснулось каждого, и скажем "нет" всему, что нам предлагают вместо новых выборов
                                       </p>
                                   </div>
                               </div>
                               <div className='w-30'><img src={Done1} alt='done' /></div>
                           </div>
                       </div>

                       <div className='app-section-block-right d-web'>
                            <img src={Paper} width={videoWidth/1.7} alt='paper' />
                       </div>
                   </div>
               </div>
           </div>
           <div className='p-relative wp-100 d-flex-center'>
               <div className='app-centered-container p-50 bg-white p-absolute ta-left' style={{top: -100}}>
                   <div className={width > 600 ? 'wp-50' : 'wp-100'}>
                       <h2>ОБЪЕДИНИМСЯ НА ПЛАТФОРМЕ "ГОЛОС"</h2>
                       <p className='mt-20'>Укажите как вы готовы действовать на “референдуме” – нам важен каждый голос!</p>
                       <p className='mt-20'>Получайте актуальную информацию о кампании, чтобы действовать вместе</p>
                       <div className='mt-20 d-flex-ac'>
                           <a className='td-none' href='https://bit.ly/ref-golos-viber' target='_blank' rel='noopener noreferrer'>
                               <button className='button-main viber d-flex-ac'>
                                   <img src={ViberLogo} width={24} alt='viber' />
                                   <div className='ml-5'>Viber</div>
                               </button>
                           </a>
                           <a className='td-none' href='https://bit.ly/ref-golos-tg' target='_blank' rel='noopener noreferrer'>
                               <button className='ml-10 button-main telegram d-flex-ac'>
                                   <img src={TgLogo} width={24} alt='telegram' />
                                   <div className='ml-5'>Telegram</div>
                               </button>
                           </a>
                       </div>

                   </div>
               </div>
           </div>
       </div>


    );
}

export default Instructions;
